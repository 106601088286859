/* Place your extensions here to make migration to a new release easier. */
@import "./custom_css/css/style.css";
@import "./custom_css/css/nice-select.css";
@import "./custom_css/css/font-awesome.min.css";
@import "./custom_css/css/slicknav.min.css";
@import "./custom_css/css/elegant-icons.css";
// @import "primeicons/primeicons.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap")
      format("woff2");
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap")
    format("woff2");
}

@media only screen and (max-width: 1026px) {
  .marketingImage {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .d-block img {
    width: 20px;
  }
}

button,
a {
  cursor: pointer;
}
$primary-color: #ffa200 !important;
$primary-color-mid: hsla(38, 100%, 50%, 0.3);
$secondary_color: #1e0030;
$translucent-color: rgba(255, 244, 204, 0.42);
$translucent-black: rgba(68, 68, 68, 0.507);
// $school-pay-primary-color: #62e6e6;
$school-pay-primary-color: #39c5c5;
$school-pay-primary-color-light: #b6e2e2;
$school-pay-secondary-color: #43c6db;

.hovereredClass:hover {
  cursor: pointer;
}

.custombutton {
  width: "250px";
  margin-bottom: "15px";
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: visible;
  margin-top: 200px;
  margin-left: 40%;
}
.tpl_top,
.tplb_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tp_left {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.tp_left,
.tp_center {
  width: 32% !important;
  margin: 0 auto !important;
}
.tpl_top {
  height: 7rem;
  background: #ffa200ad;
  padding: 1rem;
  border-radius: 0.5rem;
}
.tpl_top .tplt_txt {
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
}
.top_picks_c {
  width: 95% !important;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/*Seed Green*/
.seed-green {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 7.5rem;
  overflow: visible;
}

.sign_in_c {
  position: relative;
}
.sign_in_c .dropdown-menu {
  position: absolute !important;
  top: 135%;
  left: -136%;
  transform: translate(0%, 0%) !important;
  row-gap: 1rem;
  z-index: 999;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top: none !important;
}
.header_top_row {
  background-color: $primary-color;
}
.seed-button {
  background-color: $primary-color !important;
  color: white !important;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  // display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preview img {
  max-width: 100px;
}

.multi-preview img {
  max-width: 100px;
  padding: 5px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-custom {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* Container needed to position the button. Adjust the width as needed */
.container {
  position: relative;
  width: 100%;
}

// /* Make the image responsive */
// .container img {
//   width: 100%;
//   height: auto;
// }

/* Style the button and place it in the middle of the container/image */
.container .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .icon:hover {
  background-color: black;
}

.dataview-demo {
  .p-dropdown {
    width: 14rem;
    font-weight: normal;
  }

  .product-name {
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    img {
      width: 150px;
      margin-right: 2rem;
    }

    .product-list-detail {
      flex: 1 1 0;
    }

    .p-rating {
      margin: 0 0 0.5rem 0;
    }

    .product-price {
      font-weight: 500;
      margin-bottom: 0.5rem;
      align-self: flex-end;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .p-button {
      margin-bottom: 0.5rem;
    }

    .product-badge-out {
      background-color: #dd2222;
      text-align: center;
      border-radius: 5px;
      color: white;
    }

    .product-badge-in {
      background-color: $primary-color;
      text-align: center;
      border-radius: 5px;
      color: white;
    }
  }

  .product-grid-item {
    margin: 0.3em;
    border-style: none;
    height: auto;

    .product-grid-item-top,
    .product-grid-item-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .p-button {
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: 9rem;
      border-radius: 1rem;
      object-fit: cover;
    }

    .product-grid-item-content {
      text-align: center;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .product-badge-out {
      background-color: #dd2222;
      text-align: center;
      border-radius: 3px;
      color: white;
      padding: 2px 10px 2px 5px;
      font-size: medium;
      font-weight: 600;
    }

    .product-badge-in {
      background-color: $primary-color;
      text-align: center;
      border-radius: 3px;
      color: white;
      padding: 2px 5px 2px 5px;
      font-size: medium;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 576px) {
    .product-list-item {
      flex-direction: column;
      align-items: center;

      img {
        width: 75%;
        margin: 2rem 0;
      }

      .product-list-detail {
        text-align: center;
      }

      .product-price {
        align-self: center;
      }

      .product-list-action {
        display: flex;
        flex-direction: column;
      }

      .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .product-badge-out {
        background-color: #dd2222;
        text-align: center;
        border-radius: 5px;
        color: white;
      }

      .product-badge-in {
        background-color: $primary-color;
        text-align: center;
        border-radius: 5px;
        color: white;
      }
    }
  }
}
.p-card {
  box-shadow: none !important;
}
.carousel-demo {
  padding: 5px;
  margin: 10px 0 0 10px;
  .product-item {
    .product-item-content {
      border: 1px solid var(--surface-d);
      border-radius: 3px;
      margin: 0.3rem;
      text-align: center;
      padding: 2rem 0;
    }

    .product-image {
      width: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }
}

iframe.embed-responsive-item {
  width: 100%;
  height: 100%;
}

.no-item-found {
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.paginator-demo {
  .p-button.p-button-icon-only {
    border-radius: 0;
  }

  .image-gallery {
    text-align: center;
    padding: 1rem;
  }
}

a {
  text-decoration: underline;
}

/* A link that has not been visited */
a:link {
  color: var($primary-color);
}

/* A link that has been visited */
a:visited {
  color: black;
}

/* A link that is hovered on */
a:hover {
  color: green;
}

/* A link that is selected */
a:active {
  color: black;
}

/*Large Screens*/
@media only screen and (min-width: 1200px) {
  .login-panel {
    padding: 20px;
    // min-height: calc(130vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .login-form,
  .register_form {
    width: 65%;
    margin: 0 auto;
    text-align: center;
  }

  .content-section {
    width: 100%;
    margin: 0 auto 2rem auto;
  }

  .content-section-create {
    margin: 10px auto;
    position: relative;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 40px;
    border-radius: 8px;
    width: 90%;
    margin: 5px 20px;
  }

  .text-button-create {
    height: 45px;
    border-radius: 8px;
    width: 195%;
    margin: 20px 15px 0 20px;
  }

  .text-button-google {
    height: 50px;
    border-radius: 8px;
    width: 100%;
  }
  .create__background {
    height: 165vh;
    width: auto;
  }

  .logo__position {
    padding-left: 30%;
  }
}

/*Small Screens, laptops*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-panel {
    padding: 20px;
    // min-height: calc(100vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .login-form,
  .register_form {
    width: 80%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
  }

  .content-section {
    width: 100%;
    margin: 30px auto;
  }

  .content-section-create {
    width: 60%;
    margin: 10px auto;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 50px;
    border-radius: 8px;
    width: 100%;
    margin: 5px 0;
  }

  .text-button-create {
    height: 50px;
    border-radius: 8px;
    width: 205%;
  }

  .text-button-google {
    height: 50px;
    border-radius: 8px;
    width: 87%;
    margin: 2% 6%;
  }

  .create__background {
    height: 85vh;
    width: auto;
  }

  .logo__position {
    padding-left: 30%;
  }
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-panel {
    padding: 20px;
    // min-height: calc(100vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .login-form,
  .register_form {
    width: 80%;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
  }

  .content-section {
    width: 100%;
    margin: 30px auto;
  }

  .content-section-create {
    width: 75%;
    margin: 10px auto;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 50px;
    border-radius: 8px;
    width: 100%;
  }

  .text-button-create {
    height: 50px;
    border-radius: 8px;
    width: 205%;
  }

  .text-button-google {
    height: 50px;
    border-radius: 8px;
    width: 77%;
    margin: 5% 11.5%;
  }

  .create__background {
    height: 100vh;
    width: auto;
  }
  .logo__position {
    padding-left: 30%;
  }
}

/*Wide Mobile Devices*/
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .login-panel {
    padding: 20px;
    // min-height: calc(100vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .login-form,
  .register_form {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .content-section {
    width: 100%;
    margin: 30px auto;
  }

  .content-section-create {
    width: 70%;
    margin: 10px auto;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 50px;
    border-radius: 8px;
    width: 85%;
    margin: 0 10% 0 5%;
  }

  .text-button-create {
    height: 50px;
    border-radius: 8px;
    width: 85%;
    margin: 5% 10% 0 5%;
  }

  .text-button-google {
    height: 50px;
    border-radius: 8px;
    width: 89%;
    margin: 2% 3% 0 3%;
  }

  .create__background {
    height: 165vh;
    width: auto;
  }

  .logo__position {
    padding-left: 30%;
  }
}

/*Extra Small Devices*/
@media only screen and (max-width: 360px) {
  .login-form,
  .register_form {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .login-panel {
    padding: 10px;
    // min-height: calc(100vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .content-section {
    width: 100%;
    margin: 30px auto;
  }

  .content-section-create {
    width: 75%;
    margin: 10px auto;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 40px;
    border-radius: 8px;
    width: 85%;
    margin: 0 10% 0 5%;
  }

  .text-button-create {
    height: 40px;
    border-radius: 8px;
    width: 85%;
    margin: 20% 10% 0 5%;
  }

  .text-button-google {
    height: 40px;
    border-radius: 8px;
    width: 90%;
    margin: 5% 5% 0 2.5%;
  }

  .create__background {
    height: 180vh;
    width: auto;
  }

  .logo__position {
    padding-left: 15%;
  }
}

/*Medium Mobile Devices*/
@media only screen and (min-width: 361px) and (max-width: 480px) {
  .login-form {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    opacity: 0.9;
    text-align: center;
  }

  .login-panel {
    padding: 10px;
    // min-height: calc(100vh - 0px);
    width: 100%;
    margin: 0 auto;
  }

  .content-section {
    width: 100%;
    margin: 30px auto;
  }

  .content-section-create {
    width: 75%;
    margin: 10px auto;
    .card {
      border-radius: 20px;
    }
  }
  .text-box-create {
    height: 40px;
    border-radius: 8px;
    width: 85%;
    margin: 0 10% 0 5%;
  }

  .text-button-create {
    height: 40px;
    border-radius: 8px;
    width: 85%;
    margin: 20% 10% 0 5%;
  }

  .text-button-google {
    height: 40px;
    border-radius: 8px;
    width: 90%;
    margin: 5% 5% 0 2.5%;
  }

  .create__background {
    height: 160vh;
    width: auto;
  }

  .logo__position {
    padding-left: 15%;
  }
}

.image-center {
  margin-right: auto;
  margin-left: auto;
}

.logo-center {
  margin-right: auto;
  margin-top: 25%;
  margin-left: auto;
}

.inner-form {
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.signInButton {
  width: 35.5em;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.search-input {
  height: 39px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.logo-padding {
  margin-left: 30px;
}
.logo-padding a img {
  width: 100%;
}
.re_order {
  text-decoration: none;
  row-gap: 0.2rem;
}
.re_order_txt,
.acc_sign_o {
  font-size: 12px;
  font-weight: 500 !important;
}
.acc_sign_o {
  color: #1e0030;
}
.cart_num_items i {
  font-size: 1.5rem;
}
.items_nr {
  width: 0.8rem;
  height: 0.8rem;
  background: #1e0030e8;
  left: 108%;
  top: 0%;
  border-radius: 2rem;
  transform: translate(-50%, 0%);
}
.my_title_txt {
  line-height: 22px;
}
.search-form {
  background: white;
  border-radius: 5rem;
  height: 2.3rem;
  position: relative;
  margin: 0;
  width: 100%;
}
.hero__item {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}
.header__top {
  position: relative;
}
.header_spread_cats {
  font-size: 0.8rem;
  background: hsla(40, 95%, 58%, 0.3);
  position: fixed;
  top: 87px;
  left: 0;
  backdrop-filter: blur(2rem);
}
.same_cats {
  column-gap: 2rem;
  padding: 0;
  margin: 0;
}

.same_cats .cat_item {
  list-style: none;
  white-space: nowrap;
  color: #1e0030e8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.header_btm_lft {
  column-gap: 0.5rem;
  padding-left: 1.3rem !important;
}
.how_delivery {
  color: #1e0030e8;
}
.header_btm_lft > i {
  color: #1e0030e8;
  font-size: 1.3rem;
}
.side_ads_mobile {
  .card {
    position: relative;
    border: 1px solid $primary-color-mid !important;
    border-radius: 1rem !important;
    overflow: hidden;
    box-shadow: 0 0.0625rem 0.125rem 0.0625rem #00000026;
  }
  .ad_content {
    margin: 2rem;
    background: white;
    padding: 1rem;
    position: relative;
    z-index: 1;
  }
}
.jWpUJH {
  left: 50% !important;
  transform: translate(-50%) !important;
  background: $secondary_color;
  height: 15px !important;
  border-radius: 1rem;
  width: 50% !important ;
  background-image: linear-gradient(
    to right,
    #051937,
    #004870,
    #007c86,
    #00ae6c,
    #a3d534
  );
}
.search-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 5rem;
  margin: 0 1rem 0 0;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  cursor: text;
  -webkit-box-shadow: 10px 10px 14px 1px rgba(00, 00, 00, 0.2);
  box-shadow: 10px 10px 14px 1px rgba(00, 00, 00, 0.2);
}
.search-icon {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(0%, -50%);
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: hsl(40, 95%, 58%);
  cursor: pointer;
}
.total_price {
  font-size: 12px;
}
.search-icon svg {
  fill: rgba(255, 255, 255, 0.877);
}
.header-icon {
  width: 1rem;
  height: 1rem;
}
.big-icon {
  font-size: 25px;
}

@media screen and (min-width: 1024px) {
  .carousel-layout {
    margin: 0 50px 0 50px;
  }
}

@media only screen and (min-width: 600px) {
  .header_mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .humberger__menu__mobile {
    display: none;
  }
  .side_ads_mobile {
    display: none;
  }
}

.galleria-demo {
  margin: 0 10px 0 10px;
  overflow: hidden;
  border-radius: 0.5rem;
  .card {
    padding: 0;
    border-radius: 1rem;
    border: 1px solid $primary-color-mid;
  }
  .kNBmiK {
    margin: 0 !important;
    height: 100%;
  }
  .sc-ifAKCX {
    height: 100%;
  }
  .gqFfkb {
    height: 100%;
  }
  .sc-bZQynM {
    height: 100%;
  }
  .custom-indicator-galleria {
    .indicator-text {
      color: #e9ecef;
      cursor: pointer;
    }

    .p-highlight {
      .indicator-text {
        color: var($primary-color);
      }
    }
  }
}

.text-danger {
  color: red;
}
/*Customer Front-End Text*/
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  margin-left: 20px;
  margin-top: 13px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.product-price:hover {
  cursor: pointer;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $primary-color;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $primary-color;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.sidebar__item__mobile {
  .sidebar__account {
    //color: black;
    font-weight: bold;
  }
  .item__link {
    margin: 5px;
    letter-spacing: 1px;
    //font-size: 1.2rem;
  }
}

.btn-spacer button {
  /* You Can Name it what you want*/
  margin-right: 5px;
}

.btn-spacer button:last-child {
  margin-right: 0;
  /*so the last one dont push the div thas giving the space only between the inputs*/
}

.product__details__text h6 {
  margin-bottom: 15px;
}

.form-field-wrapper {
  margin-top: 1em;
}
#userName,
#password > input {
  border-radius: 0.5rem;
  text-align: center;
}
.form_head {
  width: 20% !important;
  margin: 0 auto !important;
  border-bottom-right-radius: 2rem !important;
  border-bottom: 5px solid var($primary-color) !important;
}
.signup_form_head {
  width: 50% !important;
  margin: 0 auto !important;
  border-bottom: 5px solid var($primary-color) !important;
}
.reveal_blend {
  position: absolute;
  top: 7.3rem;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
}
.reveal_reg_entry {
  position: absolute;
  top: 142%;
  left: -1840%;
  width: 100vw;
  height: 90vh;
  background: rgba(0, 0, 0, 0.35);
  z-index: 99;
}
#firstName,
#emailAddress,
#lastName,
#primaryTel,
.signup_pswd {
  border-radius: 0.5rem !important;
  padding: 0.5rem 1rem !important;
}
.t_and_cs {
  font-size: 0.9rem;
  color: #1e00308e;
}
.signup_btn {
  width: 50% !important;
}
.mdc-linear-progress__bar-inner {
  background-color: blue !important;
  background-color: var(--mdc-theme-primary, blue);
}

.form-field-wrapper {
  margin-top: 2em;
}
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #333333;
  background: #ffffff;
  padding: 0.429rem 0.429rem;
  border: 1px solid #a6a6a6;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #848484;
  margin-bottom: 0.5rem;
}
.form-title {
  margin: 0 auto 0 3rem;
  color: #ffa000;
  background-image: linear-gradient(90deg, #ffa000, #1e003085, #ffa000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-family: Source Sans Pro, sans-serif;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  font-weight: 900;
  font-size: 2rem;
  width: 90%;
  border-bottom: 5px solid #1e0030;
  align-items: center;
  justify-content: center;
  display: flex;
}

.text-shadow {
  text-shadow: 1px 1px 1px $primary-color;
}

.layout-footer {
  position: absolute !important;
  bottom: 0;
  width: 100% !important;
  overflow: hidden !important;
}

@media only screen and (min-width: 768px) {
  .layout-footer {
    width: 82.1% !important;
  }
}

.enroll_school_c {
  overflow-y: hidden !important;
}
.p-md-4 {
  padding: 0.5rem !important;
}
.toast-demo button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .toast-demo button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

hr.solid {
  border-top: 2px solid #bbb;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
