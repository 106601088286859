/* Scoped styles under the .uploader-container class */

.uploader-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed hsl(38, 100%, 50%);
    height: 300px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .uploader-container .uploaded-row {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #e9f0ff;
  }
  
  .uploader-container .upload-content {
    display: flex;
    align-items: center;
  }
  .image-preview-container {
    position: relative;
    display: inline-block;
  }
  
  .delete-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
    background: white;
    border-radius: 50%;
    padding: 8px; /* Increased padding for larger area */
    font-size: 30px; /* Increase font size for a larger icon */
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for visibility */
  }
  