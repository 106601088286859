.enroll_school_c {
  width: 100%;
  height: 73vh;
  max-height: 100%;
  // overflow-y: scroll !important;

  .school_setup-form {
    width: 90%;
    height: 20rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.171);
    background: #fff;

    .form-title {
      margin: 0 auto 0 3rem;
      color: #ffa000;
      background-image: linear-gradient(90deg, #ffa000, #1e003085, #ffa000);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
      font-family: Source Sans Pro, sans-serif;
      text-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      font-weight: 900;
      font-size: 2rem;
      width: 90%;
      border-bottom: 5px solid #1e0030;
    }

    .inpu_and_label_c {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 0 auto;

      label {
        align-self: flex-start;
        color: hsl(278, 100%, 9%) !important;
        font-weight: bold;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        padding: 0 0.5rem;
      }

      input {
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid rgba(0, 0, 255, 0.515);
        text-align: center;
        background: hsl(0, 0%, 100%);
        margin: 0 auto;
        display: block;
        color: hsla(278, 100%, 9%, 0.9);
      }
    }

    .send-btn {
      // margin: 0 3rem 0 auto;
      background-image: linear-gradient(83deg, #1e0030, #1e0030);
      border: none;
      color: white;
      font-weight: bold;
      border-radius: 2rem;
      cursor: pointer;
      padding: 0.5rem 2rem;
      font-size: 1rem;
      transition: all 0.3s ease-out;
    }
  }
}
