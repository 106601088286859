.items-content,
.transactions-content,
.vendors-content {
    padding: 2rem;
    width: 100%;
    max-width: 96rem;
    margin: 0rem auto;
    transition: all 0.5s linear;

    .items-content-header,
        .transactions-content-header,
    .vendors-content-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vendors-content-title,
            .items-content-title,
            .transactions-content-title {
                white-space: nowrap;

                .vendors-star {
                        display: inline-block;
                        transform: scale(2);
                        opacity: 0;
                    }
            }
        .items-search,
        .transactions-search,
        .vendors-search {
            background: white;
            border-radius: 5rem;
            margin: 0 1rem 0 0rem;
            flex: 2;

            .search-form {
                    width: 100%;
                    height: 100%;
                    position: relative;
            
            .search-icon {
                    position: absolute;
                    top: 50%;
                    right: 1%;
                    transform: translate(0%, -50%);
                    height: 1.8rem;
                    width: 1.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    /* background: #FAB62D; */
                    background: hsl(188,
                        51%,
                        52%);

                    .header-icon {
                            width: 1rem;
                            height: 1rem;
                    
                        }
            
                }
                .search-input {
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    border: 1px solid hsl(188,51%,52%);
                    border-radius: 5rem;
                    margin: 0 1rem 0 0;
                    padding: .5rem 1rem;
                    font-size: 0.8rem;
                    cursor: text;
                    transition: var(--element-transition);
                }
            }
            
        }
                .calender-n-filter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    flex: 1;
        
                    .vendors-calender-c {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 2.5rem;
                        width: 11rem;
                        background: hsl(0,0%,100%);
                        border-radius: .5rem;
                        border: 1px solid hsla(188,51%,52%, 0.5);
                        transition: var(--element-transition);
        
                        .calender-svg {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0 .5rem;
        
                            .filter-svg,
                            .calendr-icon {
                                fill: hsla(278,
                                        100%,
                                        9%, 0.5);
                            }
                        }
                    }
        
                    .vendors-filter-c,
                    .items-filter-c,
                    .transactions-filter-c {
                        position: relative;
                        overflow: visible;
                        height: 2.5rem;
                        transition: var(--element-transition);
                        width: 100%;
        
                        .calender-title-c,
                        .filter-title-c {
                            padding: 0.5rem;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            background: hsl(0,0%,100%);
                            border-radius: 2rem;
                            border: 1px solid hsla(188,
                                    51%,
                                    52%, 0.5);
                            box-shadow: 10px 10px 14px 1px rgba(0, 0, 0, 0.2);
        
                            .calender-title,
                            .filter-title {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
        
                                .calender-svg {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 .5rem;
        
                                    .filter-svg,
                                    .calendr-icon {
                                        fill: hsla(278,
                                                100%,
                                                9%, 0.5);
                                    }
                                }
        
                                .filter-txt {
                                    span {
                                        color: hsla(278,
                                                100%,
                                                9%, 0.5)
                                    }
                                }
                            }
        
                            .admin-dropdown-icon,
                            .cta,
                            .calender-dropd-i,
                            .filter-dropd-i {
                                i {
                                    color: hsla(278,
                                            100%,
                                            9%, 0.3);
                                    text-shadow: 0 10px 10px rgba(0, 0, 0, .1);
                                    display: inline-block;
                                    font-size: 0.7rem;
                                    cursor: pointer;
                                }
                            }
                        }
        
                        .admin-dropd-menu,
                        .vendors-dropd-menu {
                            --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
                            --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
                            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                            border: 1px solid hsla(188,
                                    51%,
                                    52%, 0.5);
                            position: absolute;
                            top: 100%;
                            left: 0%;
                            width: 100%;
                            background: linear-gradient(45deg, hsl(0,
                                        0%,
                                        100%), hsl(188,
                                        51%,
                                        52%));
                            padding: 1rem;
                            border-radius: .5rem;
                            z-index: 2;
                        }
                    }
                }
    }

    .items-table-c,
    .transactions-table-c,
    .vendors-table-c {
        overflow-x: auto;
        margin-top: 2rem;

        .school-banners-container {
                width: 100%;
                height: 15vh;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1rem;
                    }
                .page-title-container {
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    transform: translate(-50%, -40%);
                    font-size: calc(1rem + 0.2rem);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 .5rem 1rem 0.5rem;
                    overflow: hidden;

                    .items-title {
                            color: #1e0030e7;
                                font-size: 1.7rem;
                                text-shadow: 2px 2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px 0px 0 #fff, 0px 2px 0 #fff, -2px 0px 0 #fff, 0px -2px 0 #fff;
                        }
                    span {
                            font-size: 2rem;
                        }
                }
            }
        .school-item-info {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            .classes {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin: 5rem 0 1rem 1rem;
                align-self: flex-start;
                .classes-title{
                    font-size: 1.2rem;
                }
                p:nth-child(1) {
                        border-top: 1px solid #fab62da8;
                }

                p {
                    border-bottom: 1px solid hsl(188,51%,52%);
                    width: 100%;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    text-align: center;
                    border-top-left-radius: 2rem;
                    border-bottom-left-radius: 2rem;
                    padding: .5rem 1.5rem;
                    margin: 0 0 5px 0 !important;
                    color: hsla(278,100%,9%, 0.5);

                    &:hover {
                            transform: translateY(-5%);
                            box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
                        }
                }
                .active_class{
                    background: hsl(0, 0%,100%);
                }
                .background {
                    width: 0;
                    height: 500%;
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    transform: translate(-50%, -50%);
                    background: hsl(188,
                        51%,
                        52%);
                    transition: .8s;
                    z-index: -1;
                    border-radius: 10%;
                }
                .class-bag {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .class-name {
                            color: hsla(278,100%,9%, 0.7);
                            flex: 1;
                            font-size: .9rem;
                        }

                    .bag-container {
                        position: relative;
                        display: inline-block;
                        flex: 1;

                        .shopping-bag {
                                width: 1.5rem;
                                height: 1.5rem;
                            }
                        .items-nr {
                            width: 1.1rem;
                            height: 1.1rem;
                            border:1px solid hsl(188,51%,52%);
                            border-radius: 50%;
                            font-size: 0.8rem;
                            text-align: center;
                            position: absolute;
                            top: 15%;
                            left: 55%;
                            transition: all 0.75s ease-in-out;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .see-summary {
                    border: 1px solid hsl(188,51%,52%);
                    background: hsl(188,51%,52%);
                    color: hsl(0, 0%,100%);
                    padding: 0.5rem 1.5rem;
                    border-radius: 2rem;
                    font-size: calc(0.5rem + 0.3rem);
                    cursor: not-allowed;
                    transition: all 0.5s linear;
                    white-space: nowrap;
                }

                .submit-items {
                    border: 1px solid hsl(188,
                        51%,
                        52%);
                    cursor: pointer;
                    color: hsl(188,
                        51%,
                        52%);
                    padding: 0.5rem 1.5rem;
                    border-radius: 2rem;
                    margin-top: 1rem;
                    font-size: calc(0.5rem + 0.3rem);
                    white-space: nowrap;
                }
            }
            .school-items {
                flex: 6;

                .school-items-container{
                    .school-items-menu {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .school-items-lists{
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: center;
                            column-gap: 3rem;
                            align-items: center;
                            margin: 1rem 0 0 0;
                            padding: .7rem 0;

                            .menu-list {
                                    color: hsla(278, 100%, 9%, .8);
                                    font-size: 1rem;
                            }
                        }
                    }      

                    .items-container-sch {
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
                        min-height: 100vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        scrollbar-width: none;
                        border: 1px solid hsl(188,51%,52%);
                        margin: 0 auto 2rem 0;
                        border-radius: .5rem;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .item-container {
                            border: 1px solid hsla(188,51%,52%, 0.4);
                            margin: .5rem .3rem;
                            padding: 1rem;
                            border-radius: 0.5rem;
                            transition: all .5s ease-in-out;
                            background: hsl(0,0%,100%);
                            max-height: 28rem;

                            .item-btn {
                                min-height: 10rem;
                                position: relative;
                                margin: 0 auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .add-to-cart {
                                    position: absolute;
                                    top: 90%;
                                    left: 0%;
                                    background: hsl(188,
                                            51%,
                                            52%);
                                    color: white;
                                    padding: 0.5rem 1.5rem;
                                    border-radius: 2rem;
                                    font-size: calc(0.1rem + 1rem);
                                    font-weight: bold;
                                    cursor: pointer;
                                    transition: all 0.5s linear;
                                    transform-origin: center;

                                    .active {
                                        background: hsla(358,
                                                80%,
                                                62%, .5);
                                        padding: 0.2rem 1rem;
                                        font-size: calc(0.1rem + .8rem);
                                    }

                                    span {
                                        color: hsl(0,
                                                0%,
                                                100%);
                                    }

                                    &:hover {
                                        padding: 0.5rem 1.8rem;
                                    }
                                }
                                .added_to_class{
                                    position: absolute;
                                        top: 90%;
                                        left: 0%;
                                        border: 1px solid hsl(188,
                                                51%,
                                                52%);
                                        color: hsl(188,
                                                51%,
                                                52%);
                                    padding: 0.2rem .5rem;
                                        border-radius: 2rem;
                                        font-size: calc(0.1rem + 1rem);
                                        font-weight: bold;
                                        cursor:not-allowed;
                                        transition: all 0.5s linear;
                                        transform-origin: center;
                                   
                                        .active {
                                            background: hsla(358,
                                                    80%,
                                                    62%, .5);
                                            padding: 0.2rem 1rem;
                                            font-size: calc(0.1rem + .8rem);
                                        }
                                    
                                       
                                
                                }
                                .edit_added_item{
                                    font-size: .7rem;
                                        color: #f72323cc;
                                    position: absolute;
                                        top: 95%;
                                        right: 10%;
                                        border-bottom: 1px solid hsla(188, 51%, 52%, 0.781);
                                }
                            }

                            .item-description {
                                width: 100%;
                                margin: 3rem auto 0rem auto;

                                .price-container {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-wrap: nowrap;

                                    .price {
                                        font-size: calc(0.5rem + 0.5rem);
                                        .currency_symbol{
                                            font-size: .6rem;
                                            font-weight: bolder;
                                        }
                                    }
                                }

                                .details {
                                    margin: 1rem auto;
                                    overflow: hidden;
                                    // text-overflow: ellipsis;
                                    // white-space: nowrap;
                                strong{
                                    color: black;
                                }
                                                                }

                                .qtn {
                                    display: flex;
                                    width: 100%;
                                    justify-content:center;
                                    align-items: center;
                                    margin: calc(.2rem + 0.5rem) 0rem;
                                    border-top: 1px solid #ffa20029 !important;

                                    .qtn-title {
                                        font-weight: bold;
                                        font-size: calc(0.3rem + 0.5rem);
                                    }

                                    .quantity {
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;

                                       

                                        .qtn-btn {
                                            border-radius: 50%;
                                            background: hsl(188,51%,52%);
                                            width: 1.5rem;
                                            height: 1.5rem;
                                            font-size: calc(1rem + 0.3rem);
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;


                                            span {
                                                color: white;
                                            }
                                        }

                                        .qtn-nr {
                                            outline: none;
                                            border: none;
                                            width: 1.5rem;
                                            text-align: center;
                                            height: calc(0.5rem + 0.7rem);
                                        }
                                    }

                                   
                                }
                                .vendor_name {
                                    font-size: .7rem;
                                    margin: 1rem 0 0 0 !important;
                                    text-align: left;
                                    text-align: center;
                                    color: gray;
                                    background: #0000ff1a;
                                    border-radius: .4rem;
                                    padding: .2rem;
                                    white-space: nowrap;

                                    span{
                                        color: hsl(38, 100%, 50%);
                                        font-weight: bold;
                                    }
                                }
                            }

                            img {
                                width: 60%;
                                height: 60%;
                                object-fit: contain;
                                transition: all 0.5s ease-in-out;
                                transform-origin: top;

                                &:hover {
                                    transform: scale(1.1);
                                }

                            }

                        }

                    }

                }
           
            }
        }
    }
}