@import "./custom_css/css/style.css";
@import "./custom_css/css/nice-select.css";
@import "./custom_css/css/font-awesome.min.css";
@import "./custom_css/css/slicknav.min.css";
@import "./custom_css/css/elegant-icons.css";
@import "primeicons/primeicons.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap")
      format("woff2");
  }
}

@layer components {
  .form-control {
    @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[--primary-color] focus:ring focus:ring-[--primary-color] focus:ring-opacity-50;
  }
}
* {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap")
    format("woff2");
  scroll-behavior: smooth;
}

:root {
  --primary-color: hsl(38, 100%, 50%) !important;
  --primarylight-color: hsl(38, 100%, 60%) !important;
  --primary-color-hover: hsl(38, 100%, 45%);
  --excel-color: hsl(115, 90%, 35%);
  --pdf-color: hsl(0, 90%, 49%);
  --primary-color-mid: hsla(38, 100%, 50%, 0.3);
  --secondary_color: #1e0030;
  --primary-color-light: hsl(38, 78%, 87%);
  --translucent-color: rgba(255, 244, 204, 0.42);
  --translucent-black: rgba(68, 68, 68, 0.507);
  /* --school-pay-primary-color: #62e6e6; */
  --school-pay-primary-color: #39c5c5;
  --school-pay-secondary-color: #43c6db;
  --school-pay-secondary-color-light: #b2e9f1;
  --school-pay-primary-color-light: #e5f4f7;
}

button,
span {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.filled-button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.filled-button:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.outlined-button {
  background-color: transparent;
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
}

.outlined-button:hover {
  background-color: var(--primary-color);
  color: white;
}

@keyframes growWidth {
  0% {
    width: 0;
  }
  100% {
    width: 300px;
  }
}

.animate-growWidth {
  animation: growWidth 3s linear forwards infinite;
}

label {
  @apply text-xs font-semibold text-gray-600;
}

/* Apply styles to input elements of type text, number, and select */
input[type="text"],
input[type="number"],
select {
  @apply text-sm;
}

.p-inputtext.p-component {
  @apply text-sm;
}
.form-control {
  @apply text-sm;
}
.icon-button {
  color: var(--primary-color) !important;
}

h6 {
  @apply text-sm font-semibold text-[--secondary_color];
}

.footer-link {
  @apply text-gray-300 hover:text-white transition-colors duration-200;
}

.footer-heading {
  @apply text-white font-semibold mb-4 text-lg;
}

.footer-section {
  @apply flex flex-col space-y-2;
}

/* Add smooth scrolling to the entire page */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}

/* Animation for product cards */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-card {
  animation: fadeIn 0.5s ease-out forwards;
}
.ql-editor{
  height: 200px !important;
  max-height: 200px !important;
  overflow: auto !important;
}