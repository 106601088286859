$secondary_color: hsl(38, 100%, 50%);
$white_color: rgb(236, 226, 226);

.layout-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3rem;
  padding: 20px 0;
  margin: 2rem 0;
  flex-direction: column;
  position: relative;

  .icon_n_btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    column-gap: 0.5rem;

    .layout-profile-link {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;

      .username {
        white-space: nowrap;
        font-size: 0.7rem;
      }
    }
  }
  img {
    width: 2.2rem;
  }
  .circle_profile {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white_color;
    font-size: 0.5rem;
  }

  .layout-profile-link {
    cursor: pointer;
    display: inline-block;
    @include transition(color $transitionDuration);

    i {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    width: 95%;
    overflow: hidden;
    @include transition-property(all);
    @include transition-duration(0.4s);
    @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

    &.layout-profile-expanded {
      min-height: 25vh;
      width: 13rem;
      border-end-end-radius: 1rem;
      margin-top: 0.5rem;
      position: absolute;
      top: 80%;
      z-index: 1;
      left: -20%;
      backdrop-filter: blur(1rem);
      background:hsla(38, 100%, 50%,.5);
      border-bottom: 2px solid #fff;
      border-right:2px solid #fff;
      box-shadow:
      0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0.1px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0.1px 12.5px 10px rgba(0, 0, 0, 0.035),
      0.2px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0.4px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      1px 100px 80px rgba(0, 0, 0, 0.07);

      @include transition-property(all);
      @include transition-duration(0.4s);
      @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));
    }

    &.layout-profile-expanded-menu {
      border-end-end-radius: 1rem;
      max-height: 500px;
      width: 12.5rem;
      margin-top: 0.5rem;
      backdrop-filter: blur(1rem);
      background:hsla(40, 28%, 90%, 0.5) !important;
      border-bottom: 2px solid #fff;
      border-right:2px solid #fff;

  

      @include transition-property(all);
      @include transition-duration(0.4s);
      @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));
    }
    li {
      button {
        width: 90%;
        margin: 0 0 0 5%;
        border-bottom: 1px solid #fff;
        padding: 1em;
       
        border-radius: 0;
        cursor: pointer;
        @include transition(color $transitionDuration);
        color: #08041ab2;

        &:hover {
          color: $menuitemHoverColor;
        }

        span {
          margin-left: 0.25em;
          vertical-align: middle;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.layout-sidebar-light {
  .layout-profile {
    .layout-profile-link {
      color: $menuitemColor;

      &:hover {
        color: $menuitemHoverColor;
      }
    }

    ul {
      background-color: $menuitemActiveBgColor;

      li {
        button {
          color: $menuitemColor;

          &:hover {
            color: $menuitemHoverColor;
          }
        }
      }
    }
  }
}

.layout-sidebar-dark {
  .layout-profile {
    .layout-profile-link {
      color: $menuitemDarkColor;

      &:hover {
        color: $menuitemDarkHoverColor;
      }
    }

    ul {
      background-color: $menuitemDarkActiveBgColor;

      li {
        button {
          color: $menuitemDarkColor;

          &:hover {
            color: $menuitemDarkHoverColor;
          }
        }
      }
    }
  }
}
