.title_container {
  background-image: linear-gradient(
    to right,
    #051937ad,
    #328cc0b9,
    #bfdfe2,
    #00bf72,
    #a6eb12e3
  );
  padding: 2rem 0;
  text-align: center;
}
.initiate_withdraw,
.otp_entry_c {
  background: white;
  margin: 0 auto 3rem auto;
  padding: 2rem 0;
  text-align: center;
}
.initiate_btn {
  width: 50%;
  background: #a8eb12;
  border-radius: 2rem;
  padding: 0.5rem;
  color: #051937;
}
.initiate_btn:hover {
  background: #a6eb12c0;
  box-shadow: 10px 10px 14px 1px rgba(00, 00, 00, 0.2);
  letter-spacing: 0.094em;
}
.msg_c {
  background: white;
  width: 60%;
  margin: 3rem auto 0 auto;
  padding: 1rem;
  text-align: center;
  color: #ae0d0dc4;
  font-size: 1rem;
  small {
    display: block;
    text-decoration: underline;
    color: #051937;
    margin-bottom: 1rem;
  }
}
.divider {
  width: 40%;
  margin: 0 auto;
}
.otp_entry_c {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;

  input {
    border: 1px solid #5c7893c4;
    width: 3rem;
    height: 3rem;
    text-align: center;
    font-size: 2rem;
    border-radius: 6px;
  }
}
